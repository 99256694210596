<template>
  <div class="contact-form">
    <h5 class="content-title">{{ $t('contact.moreInfo') }}</h5>
    <div class="field">
      <div class="control">
        <input class="input" :class="{ 'is-danger': $v.form.fullName.$error }" type="text" :placeholder="$t('contact.fullName')" required v-model.trim="$v.form.fullName.$model">
      </div>
      <p v-if="$v.form.fullName.$error && !$v.form.fullName.required" class="help is-danger">{{ $t('error.fieldRequired', { field: $t('contact.fullName') }) }}</p>
      <p v-if="!$v.form.fullName.maxLength" class="help is-danger">{{ $t('error.maxchar', { num: 20 }) }}</p>
    </div>

    <div class="field">
      <div class="control">
        <input class="input" :class="{ 'is-danger': $v.form.lastName.$error }" type="text" :placeholder="$t('contact.lastName')" required v-model.trim="$v.form.lastName.$model">
      </div>
      <p v-if="$v.form.lastName.$error && !$v.form.lastName.required" class="help is-danger">{{ $t('error.fieldRequired', { field: $t('contact.lastName') }) }}</p>
      <p v-if="!$v.form.lastName.maxLength" class="help is-danger">{{ $t('error.maxchar', { num: 20 }) }}</p>
    </div>

    <div class="field">
      <div class="control">
        <input class="input" :class="{ 'is-danger': $v.form.email.$error }" type="email" :placeholder="$t('contact.email')" required v-model.trim="$v.form.email.$model">
      </div>
      <p v-if="$v.form.email.$error && !$v.form.email.required" class="help is-danger">{{ $t('error.fieldRequired', { field: $t('contact.email') }) }}</p>
      <p v-if="!$v.form.email.email" class="help is-danger">{{ $t('error.emailInvalid') }}</p>
    </div>

    <div class="field">
      <div class="control">
        <input class="input" :class="{ 'is-danger': $v.form.company.$error }" type="text" :placeholder="$t('contact.company')" required v-model.trim="$v.form.company.$model">
      </div>
      <p v-if="$v.form.company.$error && !$v.form.company.required" class="help is-danger">{{ $t('error.fieldRequired', { field: $t('contact.company') }) }}</p>
    </div>

    <div class="field">
      <div class="control">
        <input class="input" :class="{ 'is-danger': $v.form.phone.$error }" type="tel" :placeholder="$t('contact.phone')" required v-model.trim="$v.form.phone.$model">
      </div>
      <p v-if="$v.form.phone.$error && !$v.form.phone.required" class="help is-danger">{{ $t('error.fieldRequired', { field: $t('contact.phone') }) }}</p>
      <p v-if="!$v.form.phone.numeric" class="help is-danger">{{ $t('error.fillNumber') }}</p>
    </div>

    <div class="field">
      <div class="control">
        <input class="input" :class="{ 'is-danger': $v.form.country.$error }" type="text" :placeholder="$t('contact.country')" required v-model.trim="$v.form.country.$model">
      </div>
      <p v-if="$v.form.country.$error && !$v.form.country.required" class="help is-danger">{{ $t('error.fieldRequired', { field: $t('contact.country') }) }}</p>
    </div>

    <div class="field">
      <div class="control">
        <textarea class="textarea" :class="{ 'is-danger': $v.form.description.$error }" :placeholder="$t('contact.description')" required v-model.trim="$v.form.description.$model"></textarea>
      </div>
      <p v-if="$v.form.description.$error && !$v.form.description.required" class="help is-danger">{{ $t('error.fieldRequired', { field: $t('contact.description') }) }}</p>
    </div>

    <div class="field">
      <div class="control">
        <button class="button is-link is-fullwidth" @click="validateBeforeSubmit()" :disabled="sentLoading"><div class="lds-dual-ring" v-show="sentLoading"></div>{{ $t('contact.send') }}</button>
      </div>
      <p v-if="$v.form.$error" class="help is-danger">{{ $t('error.fillFormCorrect') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { required, email, numeric, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'ContactUsForm',
  data: () => ({
    form: {
      fullName: '',
      lastName: '',
      company: '',
      email: '',
      phone: '',
      country: '',
      description: '',
    },
    sentLoading: false,
    submitStatus: null
  }),
  validations: {
    form: {
      fullName: { required, maxLength: maxLength(20)},
      lastName: { required, maxLength: maxLength(15) },
      email: { required, email },
      company: { required },
      phone: { required, numeric },
      country: { required },
      description: { required },
    }
  },
  methods: {
    async validateBeforeSubmit () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sentLoading = true;
        const parseUploadAPI = axios.create({
          baseURL: 'https://hbq6ayol51.execute-api.us-west-2.amazonaws.com/',
          headers: {
            'Authorization': 'Basic YW9pdGVrLWlndWFyZDppZ3VhcmQ=',
            'Content-Type': 'application/json'
          }
        });
        await parseUploadAPI.post('contactus', {
          'fullName': this.form.fullName,
          'lastName': this.form.lastName,
          'email': this.form.email,
          'company': this.form.company,
          'phone': this.form.phone,
          'country': this.form.country,
          'description': this.form.description,
          'debugMail': 'sales@aoitek.com'
        }).then((res) => {
          if(res.data.message === 'OK') {
            alert(this.$t('pageSuccess.sendmailsuccess'));
            this.sentLoading = false;
            this.submitStatus = 'OK'
            this.form.fullName = '';
            this.form.lastName = '';
            this.form.email = '';
            this.form.company = '';
            this.form.phone = '';
            this.form.country = '';
            this.form.description = '';
            this.$v.$reset();
          }
        }).catch(err => {
          alert(err.message);
        })
      } else {
        this.submitStatus = 'ERROR';
      }
    }
  }
}
</script>

<style scoped lang="sass">
.contact-form
  padding: 45px
  border-radius: 5px
  background-color: #e5e5e5
  .content-title
    margin-bottom: 2rem
  .field
    margin-bottom: 2rem
</style>
